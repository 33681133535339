import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'

import { useTranslation } from 'react-i18next'
import { useStores } from "@hooks/useStores"

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import NewsArticle from "@components/blocks/news/Article"
import CharaIntro from "@components/blocks/comic/CharaIntro"
// import DetailBanner from "@components/blocks/comic/DetailBanner"

import css from "@css/components/blocks/common/Page.module.styl"

export default function ComicsPage({data, pathContext})
{
	console.log(data)
	const { appStore } = useStores()
	const {t} = useTranslation()
	const { wordpressWpComics, comic_ogp} = data
	const { title, excerpt, categories, path, featured_media} = wordpressWpComics
	const contentRef = React.useRef()
	useEffect(()=>{
		// let images = contentRef.current.querySelectorAll('img')
		if (appStore.locomotiveScroll) {
			appStore.locomotiveScroll.current.update()
		}
	})
	return (
		<React.Fragment>
			<SEO
				title={`${title} | COMICS`}
				description={excerpt}
				pathname={path}
				image={comic_ogp.publicURL}
				isArticle
			/>
			<div className={css.container}>
				<PageHeader l1="Comics" l2={t("comics__headerTitle2")}/>
				<div className={css.content} ref={contentRef}>
					<CharaIntro/>
					{/* <DetailBanner /> */}
					<NewsArticle
						type="comics"
						pageContext={{ listPath:"/comics/", ...pathContext }}
						{...data.wordpressWpComics} isThumbnail={false} pagination/>
				</div>
			</div>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
	query ComicsByID($id: String!) {
		comic_ogp : file(relativePath: { eq: "Comic__img__ogp.jpg" }) {
			publicURL
		}
		wordpressWpComics(id: { eq: $id }) {
			id
			title
			slug
			content
			date(formatString: "YYYY-MM-DD")
			path
			featured_media {
				localFile {
				    childImageSharp {
				        fluid(maxWidth: 860, maxHeight: 480) {
				            ...GatsbyImageSharpFluid
				        }
				    }
				    publicURL
				}
			}
		}
	}
`
