import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"
// import Accordion from "@components/Accordion"
// import ArrowSvg from "@images/Comic__icn__arrow.svg"

import css from "@css/components/blocks/comic/CharaIntro.module.styl"

export default function CharaIntro()
{
    const q = useStaticQuery(graphql`
        query {
            intro : file(relativePath: { eq: "Comic__img__charactor-intro@2x.png" }) {
                childImageSharp { fluid(maxWidth: 600) { ...GatsbyImageSharpFluid } }
            }
        }
    `)

    return(
        <div className={css.container}>
            <div className={css.banner}>
                <figure className={css.thumbnail}>
                    <FluidImage data={q.intro} />
                </figure>
                <div className={css.detail}>
                    <Link to="/comics/character/" className={css.button}>
                        キャラクター紹介を見る
                        <span className={css.icon}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
